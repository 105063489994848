import Amplify from "aws-amplify";
import { find } from "lodash";
import rawCfOutput from "./cf-output.json";

const stageName = process.env.REACT_APP_STAGE_NAME!;

const getCfOutput = (outputName: string) =>
  find(rawCfOutput, ({ Name }) => Name === outputName)?.Value;

const awsconfig = {
  aws_appsync_graphqlEndpoint: getCfOutput(
    `awsappsyncgraphqlendpointhhb${stageName}`
  ),
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in
  aws_project_region: getCfOutput(`awsprojectregionhhb${stageName}`),
  Storage: {
    AWSS3: {
      bucket: getCfOutput(`awsstoragebucketnamehhb${stageName}`),
      region: getCfOutput(`awsprojectregionhhb${stageName}`),
    },
  },
  Auth: {
    identityPoolId: "eu-central-1:3115b311-fa5a-42be-885b-a662f2ec207c",
    region: getCfOutput(`awsprojectregionhhb${stageName}`),
    userPoolId: getCfOutput(`awsuserpoolsidhhb${stageName}`),
    userPoolWebClientId: getCfOutput(`awsuserpoolswebclientidhhb${stageName}`),
    mandatorySignIn: true,
  },
};

Amplify.configure(awsconfig);
